<template>
    <div class="feedback-container">
        <div v-if="active === 0">
            <div class="subheading">{{ stepOneOptions.title }}</div>
            <div class="heading">{{ stepOneOptions.description }}</div>
            <div class="button-options rating">
                <vs-button v-for="(option, i) in stepOneOptions.ratings"
                    :disabled="loadingFeedback"
                    :key="i"
                    @click="createSurvey(option.value)"
                    type="border">{{ option.text }}</vs-button>
            </div>
        </div>
        <div v-if="active === 1">
            <div class="heading">Anything we can improve on?</div>
            <vs-textarea maxlength="500" v-model="textComment" />
            <div class="button-options">
                <vs-button @click="cancel()" type="border" class="cancel-btn">Cancel</vs-button>
                <vs-button :disabled="loadingFeedback" @click="updateSurvey()" type="border" class="submit-btn">Submit</vs-button>
            </div>
        </div>
        
        <div v-if="active === 2">
            <div class="subheading">Feedback Received</div>
            <div class="heading">Thanks for sharing! Your insights help us to build something great together.</div>
        </div>
    </div>
</template>
<script>
import { mapActions } from "vuex";

  export default {
    props: {
        slug: {
            type: String,
            default: ''
        },
        userId: {
            type: String,
            default: ''
        },
        orgId: {
            type: String,
            default: ''
        },
        clinicId: {
            type: Array,
            default: () => []
        },
        stepOneOptionProps: {
            type: Object,
            default: () => {}
        }
    },
    data() {
      return {
        active: 0,
        textComment: '',
        localStorageUserId: '',
        localStorageOrgId: '',
        currentFeedbackSurveyId: '',
        loadingFeedback: false,
        options: {
            title: "We're all ears 🐰",
            description: "How helpful are you finding the Clinic Dashboard?",
            ratings: [
                {
                    value: "bad",
                    text: "Not Helpful 😭"
                },
                {
                    value: "neutral",
                    text: "Its Alright 😐"
                },
                {
                    value: "good",
                    text: "Super Helpful 🤩"
                }
            ],
        },
      };
    },
    computed: {
        stepOneOptions() {
            return {
                ...this.options,
                ...this.stepOneOptionProps,
            }
        }
    },
    methods: {
        ...mapActions("storeFeedbackSurvey", [
            "saveFeedbackSurvey",
            "updateFeedbackSurvey",
        ]),
        createSurvey(rating) {
            const payload = {
                "userId": (this.userId) ? this.userId : this.localStorageUserId,
                "slug": this.slug,
                "reaction": rating,
                "metadata": {
                    "orgId": (this.orgId) ? this.orgId : this.localStorageOrgId,
                    "clinicId": (this.clinicId.length) ? this.clinicId : ""
                }
            }

            this.loadingFeedback = true;
            this.saveFeedbackSurvey(payload).then((res) => {
                if(res && res.data)
                    if(res.data && res.data.data)
                        this.currentFeedbackSurveyId =res.data.data._id
                this.active = 1;
                this.loadingFeedback = false;
            })
            .catch((err) => {
                console.error("##ERROR: ", err.message);
                this.active = 1;
                this.loadingFeedback = false;
            });
        },
        updateSurvey() {
            const payload = {
                "feedbackId": this.currentFeedbackSurveyId,
                "remarks": this.textComment
            }

            this.loadingFeedback = true;
            this.updateFeedbackSurvey(payload).then((res) => {
                this.currentFeedbackSurveyId = "",
                this.textComment = ""
                this.loadingFeedback = false;
                this.active = 2;
            })
            .catch((err) => {
                this.loadingFeedback = false;
                this.active = 2;
            });
        },
        cancel() {
            this.currentFeedbackSurveyId = "",
            this.textComment = ""
            this.active = 0;
            this.disabled = false;
        }
    },
    created() {
        if(!this.userId) {
            const user = JSON.parse(
                localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
            );
            this.localStorageUserId = user.id
        }
        
        
        if(!this.orgId) {
            const orgId = localStorage.getItem(
                `${process.env.VUE_APP_PROCESS_TOKEN}OrganizationId`
            );
            this.localStorageOrgId = orgId
        }

    }
  }
</script>
<style>
.feedback-container {
    position: relative;
    background: #F2FAF8;
    border-radius: 0.5rem;
    border: 1px solid #F2F2F2;
    padding: 24px 1rem;
    margin-bottom:12px;
}

.feedback-container .subheading {
    margin-bottom: 5px;
    color: #6E6B7B;
    font-size: 14px;
    font-family: 'InterSemiBold';
}
.feedback-container .heading {
    font-size: 16px;
    font-family: 'InterSemiBold';
}

.feedback-container .button-options,
.feedback-container .vs-con-textarea {
    margin-top: 10px;
}
.feedback-container .button-options button {
    padding: 6px 12px !important;
    height: 29px;
    border: 1px solid #000000;
    background-color:#FFFFFF !important;
    margin-right: 16px;
}
.feedback-container .button-options.rating button .vs-button-text {
    font-family: 'InterSemiBold' !important;
}
.feedback-container .button-options button.submit-btn {
    color: #fff;
    background-color: #0E4333 !important;
    font-weight: 600 !important;
}
.feedback-container .button-options button.cancel-btn {
    color: #0E4333 !important;
    font-family: 'InterSemiBold' !important;
}
.feedback-container .vs-textarea {
    background-color:#fff;
    resize: none !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .feedback-container .button-options.rating {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
    }
    .feedback-container .button-options.rating button {
        margin-bottom: 8px;
    }
}
</style>