<template>
  <div class="nurseResourceSearch w-full">
    <FeedbackWidget
      slug="resource-hub-feedback"
      :stepOneOptionProps="feedbackOptions"/>

    <div>
      <h5 class="text-xl font-semibold">Results for '{{ selectedType }}'</h5>
      <p>{{ resources.length }} results</p>
    </div>

    <div class="mt-6">
      <vs-tabs
        v-model="activeTab"
        color="rgba(132, 205, 184, 1)">
        <vs-tab
          v-for="(types, i) in tabTypes"
          :key="i"
          :label="types.label"
          v-on:click="tabOnClick(types.value)">
        </vs-tab>
      </vs-tabs>
    </div>

    <div class="nurseResourceSearch-filter flex space-x-4 overflow-x-auto w-full">
      <el-select
        v-model="filter.brands"
        multiple
        filterable
        collapse-tags
        clearable
        :disabled="isFetchingLoadMore"
        popper-class="brand-filter-popper shrink-0 font-semibold text-primary"
        placeholder="Brand"
        @change="brandFilterHandleOnChange">
        <el-option
          v-for="item in brandFilters"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>

      <button
        class="category-btn shrink-0 font-semibold vs-button vs-button-border vs-button-primary"
        :disabled="isFetchingLoadMore"
        v-for="(action, i) in categoryFilters"
        :key="i"
        :class="{ 'active-btn': filter.categories.includes(action.value) }"
        @click="categoryHandleOnChange(action.value)"
      >
        {{ action.label }}
      </button>
    </div>

    <div class="nurseResourceSearch-results">
      <Card
        @fetchMore="loadMore"
        display-type="card"
        :loadMore="loadMoreProps"
        :resources="resources">
      </Card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { uniqBy } from "lodash";
import Card from "./Card.vue";
import FeedbackWidget from "../../components/insights/widgets/FeedbackWidget.vue";

export default {
  components: {
    Card,
    FeedbackWidget,
  },
  data() {
    return {
      slideTo: null,
      activeTab: 0,
      pagination: {
        limit: 12,
        page: 1
      },
      filter: {
        categories: [],
        brands: []
      },
      searchResults: [],
      isFetchingLoadMore: false,
      feedbackOptions: {
        description: "How easy was it to find what you were looking for?",
        ratings: [
            {
                value: "bad",
                text: "Not easy at all 😭"
            },
            {
                value: "neutral",
                text: "It was okay 😐"
            },
            {
                value: "good",
                text: "Super easy 🤩"
            }
        ],
      }
    };
  },
  computed: {
    loadMoreProps() {
      const { total_results_size, next_page } = this.$store.state.resourceHub.resources.searchResults
      const totalResults = total_results_size || 0
      let totalViewed = this.pagination.page * this.pagination.limit;

      if (totalViewed > totalResults) totalViewed = totalResults

      return {
        hasNextPage: !!next_page,
        totalResults: totalResults,
        totalViewed,
        isFetching: this.isFetchingLoadMore,
      }
    },
    categoryFilters() {
      let categoryFilters = []

      this.searchResults.map((data) => {
        const formatedOption = data.tags.map(i => ({ label: i, value: i }))
        categoryFilters.push(...formatedOption)
      })

      return uniqBy(categoryFilters, 'value')
    },
    brandFilters() {
      return this.$store.state.resourceHub.filters.brands
    },
    tabTypes() {
      let tabs = [{ label: "All types", value: null }]
      this.$store.state.resourceHub.filters.types.map((type) => tabs.push(type))

      return tabs;
    },
    resources() {
      // return this.$store.state.resourceHub.resources.searchResults.results;

      return this.$store.state.resourceHub.resources.searchResults.results
        .map((i) => ({ ...i, isRecent: true }))
        .filter(item => 
          item.data &&
          item.data.brand != null && item.data.brand !== undefined && item.data.brand.data && 
          item.data.category != null && item.data.category !== undefined && item.data.category.type &&
          item.data.resource_type != null && item.data.resource_type !== undefined && item.data.resource_type.type &&
          item.data.title != null && item.data.title !== undefined && item.data.title.length > 0
        )
    },
    searchQuery() {
      const query = this.$route.query
      let searchQuery = {};

      if (query.search) searchQuery.title = query.search
      if (query.type) searchQuery.category = query.type

      return searchQuery
    },
    selectedType() {
      const query = this.searchQuery

      if (query.title) return query.title

      if (!query.category) return "All types"

      const types = this.$store.state.resourceHub.filters.types;
      const matchedType = types.find((type) => type.value === query.category)

      return matchedType ? matchedType.label : ""
    },
    dropdownFilters() {
      const { categories, brands } = this.filter
      let filters = {}

      if (categories.length) filters.tags = categories.join(',')
      if (brands.length) filters.brand = brands.join(',')

      return filters
    }
  },
  methods: {
    ...mapActions("resourceHub", ["fetchAllFilters", "searchResources"]),
    ...mapActions("admin", ["getSettingsByKey"]),
    ...mapMutations("resourceHub", ["MUTATE_RESOURCE_JSON_SETTING"]),

    async search(isFromBrandFilter = true) {
      this.$vs.loading()

      try {
        const { results } = await this.searchResources({
          ...this.pagination,
          ...this.searchQuery,
          ...this.dropdownFilters
        })
        if (isFromBrandFilter) {
          this.searchResults = results
        }
      } catch (error) {
        console.log(`Error search resources: ${error}`)
      } finally {
        this.$vs.loading.close()
      }
    },

    resetFilters() {
      this.filter = {
        categories: [],
        brands: []
      }
      this.pagination.page = 1
    },

    async init(isFirstLoad = true) {
      try {
        this.$vs.loading();
        const tag = {}

        if (isFirstLoad) {
          const { category } = this.$route.query

          await this.fetchAllFilters()

          if (category) {
            this.filter.categories.push(category)
            tag.tags = category
          }

          const { data } = await this.getSettingsByKey("resourceHubSetting")
          let formattedValue = {
            main_banner: {
              header: "",
              subHeader: "",
              image: ""
            },
            first_section: {
              header: "",
              tag: ""
            },
          }

          if (Object.keys(JSON.parse(data.data.value)).length) {
            formattedValue = JSON.parse(data.data.value)
          }

          this.MUTATE_RESOURCE_JSON_SETTING(formattedValue)
        }

        const { results } = await this.searchResources({
          ...this.pagination,
          ...this.searchQuery,
          ...tag,
        })

        this.searchResults = results
      } catch (error) {
        console.log(`Error init: ${error}`)
      } finally {
        this.$vs.loading.close()
      }
    },

    findSelectedType(currenType) {
      return this.tabTypes.findIndex((i) => i.value === currenType)
    },

    tabOnClick(type) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          type
        }
      })
    },

    categoryHandleOnChange(value) {
      const { categories } = this.filter
      const index = categories.findIndex((i) => i === value)
      if (index !== -1) {
        categories.splice(index, 1)
      } else {
        categories.push(value)
      }

      this.filter.categories = categories;

      this.pagination.page = 1
      this.search(false)
    },

    async loadMore() {
      this.pagination.page += 1;
      this.isFetchingLoadMore = true

      try {
        const { results } = await this.searchResources({
          ...this.pagination,
          ...this.searchQuery,
          ...this.dropdownFilters,
          isLoadMore: true,
        })

        this.searchResults = [...this.searchResults, ...results]
      } catch (error) {
        this.pagination.page -= 1;
        console.log(`Error search resources: ${error}`)
      } finally {
        this.isFetchingLoadMore = false
      }
    },

    brandFilterHandleOnChange() {
      this.pagination.page = 1
      this.search()
    }
  },
  async created() {
    await this.init()

    const { type } = this.$route.query
    this.activeTab = this.findSelectedType(type || null)
  },
  watch: {
    "$route.query": function (val) {
      this.activeTab = this.findSelectedType(val.type || null)

      this.resetFilters();

      this.init(false);
    },
  }
};
</script>

<style lang="scss">
.brand-filter-popper {
  .el-select-dropdown__list {
    .el-select-dropdown__item {
      color: rgba(14, 67, 51, 1);

      &.selected {
        color: rgba(14, 67, 51, 1);
      }
    }
  }
}

.nurseResourceSearch {
  --button-color: #fff;
  --button-color-focus: #ebf7f4;

  --border-color: #e3ddd7;
  --border-color-focus:  #84cdb8;
  --border-radius: 10rem;

  &-filter {

    > * {
      flex-shrink: 0 !important;
    }

    .el {
      &-tag {
        border-radius: var(--border-radius);
        background: rgba(235, 247, 244, 1);
      }
      &-input {
        &__inner {
          border-radius: var(--border-radius);
          border: 1px solid var(--border-color) ;
        }
        &.is-focus {
          .el-input__inner {
            border: 1px solid var(--border-color-focus);
          }
        }
      }
    }
      
    .category-btn {
        border-radius: var(--border-radius);
        background: var(--button-color) !important;
        border: 1px solid var(--border-color) !important;

        &.active-btn {
          background: var(--button-color-focus) !important;
          border: 1px solid var(--border-color-focus) !important;
      }
    }
  }

  &-results {
    > * {
      > *:not(.NurseResourceCard-views) {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 0.5rem;
        margin-top: 2rem;

        @media screen and (max-width: 768px) {
          & {
            grid-template-columns: 1fr
          }
        }

        > * { 
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>