<template>
  <div class="w-full">
    <!-- navigation -->
    <vs-breadcrumb
      class="block mb-2 resources-breadcrumb"
      :items="populateBreadcrumbs"
    ></vs-breadcrumb>

    <div class="nurseResources-wrapper flex flex-col items-center space-y-6">
      <!-- filter -->
      <Filters></Filters>

      <!-- search results -->
      <SearchResults></SearchResults>
    </div>
  </div>
</template>

<script>
import Filters from "./resources/Filters.vue";
import SearchResults from "./resources/SearchResults.vue";

export default {
  components: {
    Filters,
    SearchResults,
  },
  computed: {
    populateBreadcrumbs() {
      const userType = this.$route.path.split('/')[1]

      return [
        { title: "Resources", url: `/${userType}/resources` },
        {
          title: "Explore all resources",
          active: true,
        },
      ];
    },
  },
};
</script>

<style lang="scss">
.resources-breadcrumb {
  .vs-breadcrumb--link {
    color: rgba(14, 67, 51, 1);
  }
  .vs-active {
    .vs-breadcrumb--text {
      color: rgba(37, 48, 44, 0.65);
    }
  }
}

.nurseResources {
  .vs {
    &-divider {
      margin: 0;
    }
  }
}
</style>
